export interface Position {
  lat: number;
  lng: number;
}

export interface Place<Details> {
  title: string;
  position: Position;
  details: Details;
}

// TODO move somewhere else
export interface Shop {
  address: string;
  open: {
    workdays: string;
    saturday: string;
    sunday: string;
  };
}

// TODO make generic in this module, and pass from the consumer
export function searchShopPlaces(
  search: string,
  places: Place<Shop>[]
): Place<Shop>[] {
  if (search.trim().length === 0) {
    return places;
  }
  return places.filter((place) => {
    const toSearch: string[] = [place.title, place.details.address];
    return toSearch.some((element) =>
      element.toLowerCase().includes(search.toLowerCase())
    );
  });
}

@if (map$ | async; as places) {
<section class="map" id="map">
  <div class="container">
    <updog-map [places]="places"></updog-map>
  </div>
</section>
}

@if(vm$ | async; as vm) {
<a [href]="vm.banner.url" class="banner">
  <img [src]="vm.banner.image.url">
</a>

<footer>
  <img [src]="vm.image.url">
  <section>
    @for (item of vm.section; track $index) {
    <article>
      <a [href]="item.url">
        <p>{{item.title}}</p>
        <p>{{item.content}}</p>
      </a>
    </article>
    }
    <article>
      <a [href]="vm.facebook">Facebook</a>
      <a [href]="vm.linkedin">LinkedIn</a>
    </article>
  </section>
  <div>
    <p>{{vm.footerBottomText}}</p>
    <p>{{vm.footerBottomSubText}}</p>
  </div>
</footer>
}
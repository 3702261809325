import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, of, shareReplay } from 'rxjs';
import { MAP_KEY } from '../../core/tokens';

@Injectable()
export class MapInitService {
  readonly loaded$ = this.http
    .jsonp(
      `https://maps.googleapis.com/maps/api/js?key=${this.key}&mapIds=197fd53456aab5dd`,
      'callback'
    )
    .pipe(
      map(() => true),
      catchError(() => of(false)),
      shareReplay(1)
    );

  constructor(
    private readonly http: HttpClient,
    @Inject(MAP_KEY) private readonly key: string
  ) { }
}

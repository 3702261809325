import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import * as showdown from 'showdown';
import { environment } from '../../environments/environment';
import { Config, ConfigDto, toConfig } from '../models/config';
import { ShopsDto, toShops } from '../models/map';
import { Page, PageDto, toActivePages } from '../models/page';
import { CollectionTypeResponse, SingleTypeResponse } from '../models/response';
import { Footer } from '../modules/footer/footer/footer.component';
import { Highlights } from '../modules/highlights/highlights.component';
import { Grid } from '../modules/home-page/grid/grid.component';
import { HighlightedNews } from '../modules/home-page/home-page/home-page.component';
import { Place, Shop } from '../modules/map/place';
import { NewsSection } from '../modules/news-section/news-section.component';
import { News } from '../modules/news/news.component';
import { RecipePage } from '../modules/recipes/recipes.component';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly http: HttpClient) { }

  migrateShops(shopsData: any) {
    return this.http.put('map', { data: { Shop: shopsData } });
  }

  getConfig(): Observable<Config> {
    return this.http
      .get<SingleTypeResponse<ConfigDto>>('config')
      .pipe(map(toConfig));
  }

  getHomeBanner(): Observable<any> {
    return this.http.get<SingleTypeResponse<any>>('home-banner').pipe(
      map((response) => {
        console.log('response', response);
        const converter = new showdown.Converter();
        return {
          ...response,
          text: converter.makeHtml(response.data.text),
          image: `${response.data?.image?.url}`,
          imageMobile: `${response.data?.image?.url}`,
          video: `${response.data?.video?.url}`,
        };
      })
    );
  }

  getContact(): Observable<any> {
    return this.http.get<SingleTypeResponse<any>>('kontakt').pipe(
      map((response) => {
        const converter = new showdown.Converter();
        console.log(response);
        return {
          ...response.data,
          images: response.data.images.map((image: { url: any }) => ({
            url: `${image.url}`,
          })),
        };
      })
    )
  }

  getGrid(): Observable<Grid> {
    return this.http.get<SingleTypeResponse<any>>('home-grid').pipe(
      map((response) => {
        const data = response.data;
        const converter = new showdown.Converter();
        return {
          ...data,
          contentFields: data.grid?.map((field: any) => ({
            ...field,
            text: converter.makeHtml(field.text),
            mediaDesktopUrl: field.mediaDesktop?.url
              ? `${field.mediaDesktop?.url}`
              : null,
            mediaMobileUrl: field.mediaMobile?.url
              ? `${field.mediaMobile?.url}`
              : null,
          })),
        };
      })
    );
  }

  getMap(): Observable<Place<Shop>[]> {
    return this.http
      .get<SingleTypeResponse<ShopsDto>>(
        'map?populate[Shop][populate][0]=position'
      )
      .pipe(map(toShops));
  }

  getNavLinks(): Observable<Page[]> {
    return this.http
      .get<CollectionTypeResponse<PageDto>>('pages')
      .pipe(map(toActivePages));
  }

  getNews(id: string): Observable<News> {
    return this.http.get<CollectionTypeResponse<any>>('news-collection').pipe(
      map((response) => {
        const item = response.data.find((news) => news.url === id);

        if (!item) {
          throw new Error(`News item with id ${id} not found.`);
        }

        item.images = Array.isArray(item.images)
          ? item.images.map((image: { url: any }) => ({
            url: `${image.url}`,
          }))
          : [];

        item.content = Array.isArray(item.content)
          ? item.content.map((content: any) => {
            const converter = new showdown.Converter();
            return {
              title: converter.makeHtml(content.title),
              content: converter.makeHtml(content.content),
              footer: content.footer,
            };
          })
          : [];

        console.log(item);
        return item;
      })
    );
  }

  getHighlights(id: string): Observable<Highlights> {
    return this.http
      .get<CollectionTypeResponse<any>>('highlights-collection')
      .pipe(
        map((response) => {
          const item = response.data.find((item) => item.url === id);
          item.images = item.images.map((image: { url: any }) => ({
            url: `${image.url}`,
          }));
          item.background = `${item.background.url}`;
          item.heroImage = `${item.heroImage.url}`;
          const converter = new showdown.Converter();
          item.content = converter.makeHtml(item.content);
          return item;
        })
      );
  }

  getNewsSection(): Observable<NewsSection> {
    return this.http.get<SingleTypeResponse<any>>('news-section').pipe(
      map((response) => {
        return {
          ...response.data,
          items: response.data.tile.map((item: any) => ({
            url: item.url,
            image: {
              url: `${item.image.url}`,
            },
          })),
        };
      })
    );
  }

  getFooter(): Observable<Footer> {
    return this.http.get<SingleTypeResponse<any>>('footer').pipe(
      map((response) => {
        return {
          ...response.data,
          banner: {
            ...response.data.banner,
            image: {
              url: `${response.data.banner.image.url}`,
            },
          },
          image: {
            url: `${response.data.image.url}`,
          },
        };
      })
    );
  }

  getRecipes(id?: string): Observable<RecipePage> {
    const recipes =
      this.http.get<CollectionTypeResponse<any>>('recipes-collection');
    const page = this.http.get<SingleTypeResponse<any>>('recipes-page');
    const converter = new showdown.Converter();

    return combineLatest([recipes, page]).pipe(
      map(([recipesData, page]) => {
        const recipes = recipesData.data.map((recipe: any) => ({
          ...recipe,
          ingredients: converter.makeHtml(recipe.ingredients),
          preparation: converter.makeHtml(recipe.preparation),
          thumbnail: {
            url: `${recipe.thumbnail.url}`,
          },
          images: recipe.images.map((image: { url: any }) => ({
            url: `${image.url}`,
          })),
        }));

        const recipe = id
          ? recipes.find((recipe: any) => recipe.url === `przepis/${id}`)
          : null;

        return {
          ...page.data,
          recipes,
          recipe,
        };
      })
    );
  }

  getHighlightedNews(): Observable<HighlightedNews> {
    return this.http.get<SingleTypeResponse<any>>('home-highlighted-news').pipe(
      map((response) => ({
        ...response.data,
        image: {
          url: `${response.data.image.url}`,
        },
      }))
    );
  }
}

interface Attributes {
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
}

export interface SingleTypeResponse<T> {
  data: T;
  meta: unknown;
}

export interface CollectionTypeResponse<T> {
  data: T[];
  meta: unknown;
}

export interface AssetDetails {
  url: string;
}

export interface AssetFormats {
  large: AssetDetails;
  medium: AssetDetails;
  small: AssetDetails;
  thumbnail: AssetDetails;
}

export interface Asset {
  url: string;
}

export function fromSingleResponse<T>(response: SingleTypeResponse<T>): T {
  return response.data;
}

export function fromCollectionResponse<T>(
  response: CollectionTypeResponse<T>
): Array<T> {
  return response.data.map((item) => item);
}

import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker, GoogleMapsModule } from '@angular/google-maps';
import { debounceTime, map, shareReplay, startWith, tap } from 'rxjs';
import { MapInitService } from '../map-init.service';
import { Place, Shop, searchShopPlaces } from '../place';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'updog-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    AsyncPipe
],
})
export class MapComponent<T> {
  @Input({ required: true }) places!: Place<Shop>[]; // TODO use generics here

  @ViewChild(GoogleMap) mapElement!: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  openedPlace?: Place<Shop>;

  readonly input = new FormControl<string>('', { nonNullable: true });

  readonly loaded$ = this.init.loaded$;
  readonly filteredPlaces$ = this.input.valueChanges.pipe(
    startWith(''),
    debounceTime(500),
    map((search) => searchShopPlaces(search, this.places)),
    tap((places) => this.zoomIntoMarkers(places)),
    shareReplay(1)
  );

  constructor(private readonly init: MapInitService) {}

  openInfoWindow(marker: MapMarker, place: Place<Shop>): void {
    this.openedPlace = place;
    this.infoWindow.open(marker);
  }

  private zoomIntoMarkers(places: Place<Shop>[]): void {
    if (places.length === 0) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    places.forEach((place) => bounds.extend(place.position));

    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.01,
        bounds.getNorthEast().lng() + 0.01
      );
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.01,
        bounds.getNorthEast().lng() - 0.01
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    this.mapElement.fitBounds(bounds);
  }
}

import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'kontakt',
    loadComponent: () =>
      import('./modules/contact-page/contact-page/contact-page.component').then((m) => m.ContactPageComponent),
  },
  {
    path: 'news/:id',
    loadComponent: () =>
      import('./modules/news/news.component').then((m) => m.NewsComponent),
  },
  {
    path: 'highlights/:id',
    loadComponent: () =>
      import('./modules/highlights/highlights.component').then(
        (m) => m.HighlightsComponent
      ),
  },
  {
    path: 'przepisy',
    loadComponent: () =>
      import('./modules/recipes/recipes.component').then(
        (m) => m.RecipesComponent
      ),
  },
  {
    path: 'przepis/:id',
    loadComponent: () =>
      import('./modules/recipes/recipe/recipe.component').then(
        (m) => m.RecipeComponent
      ),
  },
];

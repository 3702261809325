import { environment } from '../../environments/environment';
import {
  Asset,
  CollectionTypeResponse,
  fromCollectionResponse,
} from './response';

export interface PageDto {
  route: string;
  showInNav: boolean;
  title: string;
  order: number;
  icon?: Asset;
}

export interface Page {
  route: string;
  showInNav: boolean;
  title: string;
  order: number;
  iconUrl?: string;
}

export function toActivePages(
  response: CollectionTypeResponse<PageDto>
): Page[] {
  const pages: PageDto[] = fromCollectionResponse(response);

  return pages
    .filter((page) => page.showInNav === true)
    .sort((a, b) => a.order - b.order)
    .map((page) => {
      return {
        route: page.route,
        showInNav: page.showInNav,
        title: page.title,
        order: page.order,
        iconUrl: page.icon
          ? `${page.icon.url}`
          : undefined,
      };
    });
}

import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { ApiService } from './api/api.service';
import { Config } from './models/config';

@Component({
  selector: 'updog-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private api = inject(ApiService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  public lightTheme = signal<boolean>(false);
  public config$: Observable<Config> = this.api.getConfig();

  // constructor() {
  //   this.api.migrateShops(shops).subscribe();
  // }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentPath =
          this.route.snapshot.firstChild?.routeConfig?.path || '';
        this.lightTheme.set(currentPath.startsWith('highlights'));
      });
  }

}

export const shops = [
  {
    title: 'ul. 28 czerwca 1956 r 113 A, Poznań',
    city: 'Poznań',
    address: 'ul. 28 czerwca 1956 r 113 A',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '6:30-14',
    openSunday: '',
    position: { lat: 52.393023334219286, lng: 16.920727623169324 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Aleje Marcinkowskiego 2, Poznań',
    city: 'Poznań',
    address: 'ul. Aleje Marcinkowskiego 2',
    openWorkdays: 'pon-pt 6-19',
    openSaturday: '8-16',
    openSunday: '',
    position: { lat: 52.411113588780935, lng: 16.930016222393224 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'os. Armii Krajowej 101, Poznań',
    city: 'Poznań',
    address: 'os. Armii Krajowej 101',
    openWorkdays: 'Pon-Pt 7-18',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.379966791188984, lng: 16.95119920071309 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Szamotulska 26, Baranowo',
    city: 'Baranowo',
    address: 'ul. Szamotulska 26',
    openWorkdays: 'Pon-Pt 7-18',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.44466201554768, lng: 16.787105363845505 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Poznańska 30, Biedrusko',
    city: 'Biedrusko',
    address: 'ul. Poznańska 30',
    openWorkdays: 'Pon-Pt 6-19',
    openSaturday: '6-14',
    openSunday: '8-13',
    position: { lat: 52.54142187124768, lng: 16.945682275687425 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'oś. Batorego 15c, Poznań',
    city: 'Poznań',
    address: 'oś. Batorego 15c',
    openWorkdays: 'Pon-Pt 6-18',
    openSaturday: '6-14',
    openSunday: '',
    position: { lat: 52.45730215149415, lng: 16.923412465462757 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'BÓZNICZA 1/205, Poznań',
    city: 'Poznań',
    address: 'BÓZNICZA 1/205',
    openWorkdays: 'Pon-Pt 7-19',
    openSaturday: '8-14',
    openSunday: '',
    position: { lat: 52.41202250931267, lng: 16.934986934193354 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Brzechwy 56, Poznań',
    city: 'Poznań',
    address: 'ul. Brzechwy 56',
    openWorkdays: 'Pn-Sob 7-21',
    openSaturday: '8-20',
    openSunday: '',
    position: { lat: 52.4122882204861, lng: 16.832581275672418 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Przemysłowa 2, Buk',
    city: 'Buk',
    address: 'ul. Przemysłowa 2',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: '8-20',
    openSunday: '',
    position: { lat: 52.35154315011903, lng: 16.525163692716884 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Chociszewskiego 21, Poznań',
    city: 'Poznań',
    address: 'ul. Chociszewskiego 21',
    openWorkdays: 'pon-pt 6:30-14:30',
    openSaturday: '7:30-14',
    openSunday: '',
    position: { lat: 52.3922414113466, lng: 16.890965833031704 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Czeremchowa 3-5, Poznań',
    city: 'Poznań',
    address: 'ul. Czeremchowa 3-5',
    openWorkdays: 'pn-pt 7-21',
    openSaturday: '8-20',
    openSunday: '',
    position: { lat: 52.37441959379373, lng: 16.895585220950895 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Leśna 1, Czerwonak',
    city: 'Czerwonak',
    address: 'ul. Leśna 1',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: '6-15',
    openSunday: '8-14',
    position: { lat: 52.46494384607017, lng: 16.981582146042403 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'Krauthoffera 22 a, Poznań',
    city: 'Poznań',
    address: 'Krauthoffera 22 a',
    openWorkdays: 'pon-pt 6-17',
    openSaturday: '8-14',
    openSunday: '',
    position: { lat: 52.38595871881996, lng: 16.895079862687215 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Fabianowo 12, Poznań',
    city: 'Poznań',
    address: 'ul. Fabianowo 12',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: '7-14',
    openSunday: '9-14',
    position: { lat: 52.36871365649915, lng: 16.845720763440017 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Fredry 2, Poznań',
    city: 'Poznań',
    address: 'ul. Fredry 2',
    openWorkdays: 'Pon-Pt 6-19',
    openSaturday: '6-14',
    openSunday: '',
    position: { lat: 52.40859706231285, lng: 16.921228005691756 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Galla Anonima 4, Poznań',
    city: 'Poznań',
    address: 'ul. Galla Anonima 4',
    openWorkdays: 'pon-pt 7-18',
    openSaturday: '6-14',
    openSunday: '',
    position: { lat: 52.41337863890602, lng: 16.895423064221855 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Garbary 71, Poznań',
    city: 'Poznań',
    address: 'ul. Garbary 71',
    openWorkdays: 'Pon-Pt 6-19',
    openSaturday: '6-14',
    openSunday: '',
    position: { lat: 52.40910798458527, lng: 16.938076694261746 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Chudoby 18-20, Gniezno',
    city: 'Gniezno',
    address: 'ul. Chudoby 18-20',
    openWorkdays: 'Pon-pt 7-19',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.54237453697708, lng: 17.61007055409709 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'os. Jagiellońskie box 46, Gniezno',
    city: 'Gniezno',
    address: 'os. Jagiellońskie box 46',
    openWorkdays: 'Pon-pt 7-17',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.54985929508956, lng: 17.605044651783462 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Łubieńskiego 8, Gniezno',
    city: 'Gniezno',
    address: 'ul. Łubieńskiego 8',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: '6-14',
    openSunday: '',
    position: { lat: 52.53506061775548, lng: 17.599708723558184 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Dworcowa 7, Gniezno',
    city: 'Gniezno',
    address: 'ul. Dworcowa 7',
    openWorkdays: 'Pon-pt 6-20.30',
    openSaturday: '6-19.30',
    openSunday: '',
    position: { lat: 52.53010195476325, lng: 17.603899049084713 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Strażacka 1, Golina',
    city: 'Golina',
    address: 'ul. Strażacka 1',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '6-15',
    openSunday: '',
    position: { lat: 52.24324554565485, lng: 18.09756116228113 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Grunwaldzka 11, Poznań',
    city: 'Poznań',
    address: 'ul. Grunwaldzka 11',
    openWorkdays: 'pon-pt 6:30-18',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.40549791851508, lng: 16.899216669543815 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Górna Wilda 74/69, Poznań',
    city: 'Poznań',
    address: 'ul. Górna Wilda 74/69',
    openWorkdays: 'pon-sb 7-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.39754557625834, lng: 16.924771092331518 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Grunwaldzka 371, Poznań',
    city: 'Poznań',
    address: 'ul. Grunwaldzka 371',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.38192059231731, lng: 16.83081796482559 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Grunwaldzka 104a, Poznań',
    city: 'Poznań',
    address: 'ul. Grunwaldzka 104a',
    openWorkdays: 'Pon-pt 6:30-15',
    openSaturday: '8-14',
    openSunday: '',
    position: { lat: 52.39683036235143, lng: 16.869315453210252 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Hawelańska 4, Poznań',
    city: 'Poznań',
    address: 'ul. Hawelańska 4',
    openWorkdays: 'Pon-pt 6:30-15',
    openSaturday: '8-14',
    openSunday: '',
    position: { lat: 52.43670090484466, lng: 16.950738582185814 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Jackowskiego 23, Poznań',
    city: 'Poznań',
    address: 'ul. Jackowskiego 23',
    openWorkdays: 'Pon-pt 6-14',
    openSaturday: '7-13',
    openSunday: '',
    position: { lat: 52.41024938126595, lng: 16.89847396537459 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Jasielska 12J/7, Poznań',
    city: 'Poznań',
    address: 'ul. Jasielska 12J/7',
    openWorkdays: 'pon-pt 6-19',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.4450430328619, lng: 16.893615222773487 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Jeleniogórska 1/3 B, Poznań',
    city: 'Poznań',
    address: 'ul. Jeleniogórska 1/3 B',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.39138919207502, lng: 16.847559722763172 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Obornicka 12, JELONEK',
    city: 'JELONEK',
    address: 'ul. Obornicka 12',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.48923553874199, lng: 16.850867046514516 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Spokojna 20, Jerzykowo',
    city: 'Jerzykowo',
    address: 'ul. Spokojna 20',
    openWorkdays: 'Pon-Pt 6-18',
    openSaturday: '6-13',
    openSunday: '8-13.30',
    position: { lat: 52.47116468476038, lng: 17.17226383534116 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Karlińska 35, Poznań',
    city: 'Poznań',
    address: 'ul. Karlińska 35',
    openWorkdays: 'Pon-Pt 6-19',
    openSaturday: '6-15',
    openSunday: '8-14',
    position: { lat: 52.43441138768746, lng: 16.82755122393481 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Swarzędzka 2, Kobylnica',
    city: 'Kobylnica',
    address: 'ul. Swarzędzka 2',
    openWorkdays: 'Pon-Pt 5:30-18',
    openSaturday: '6-14',
    openSunday: '',
    position: { lat: 52.44581813149523, lng: 17.076018207224546 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Poznańska 58, Komorniki',
    city: 'Komorniki',
    address: 'ul. Poznańska 58',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.33847438444166, lng: 16.814487448299907 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Młyńska 38, Komorniki',
    city: 'Komorniki',
    address: 'ul. Młyńska 38',
    openWorkdays: 'Pon-pt 6:30-18',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.332494186902885, lng: 16.81273407641471 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. 11 listopada 27, Konin',
    city: 'Konin',
    address: 'ul. 11 listopada 27',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '6-13',
    openSunday: '',
    position: { lat: 52.23141225282508, lng: 18.275331792694494 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Chopina 24, Konin',
    city: 'Konin',
    address: 'ul. Chopina 24',
    openWorkdays: 'Pon-pt 6-21',
    openSaturday: '8-20',
    openSunday: '',
    position: { lat: 52.23144865488424, lng: 18.25892496111877 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Kolejowa 1, Konin',
    city: 'Konin',
    address: 'ul. Kolejowa 1',
    openWorkdays: 'Pon-Pt 6:30-19',
    openSaturday: '7-14',
    openSunday: '8-14',
    position: { lat: 52.23120438561113, lng: 18.25122478691229 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. 11 listopada 7A, Konin',
    city: 'Konin',
    address: 'ul. 11 listopada 7A',
    openWorkdays: 'Pon-pt 6.40-16',
    openSaturday: '6.30-13',
    openSunday: '',
    position: { lat: 52.2311961545314, lng: 18.269011648295972 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Szarych Szeregow 1D, Konin',
    city: 'Konin',
    address: 'ul. Szarych Szeregow 1D',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: '8-20',
    openSunday: '',
    position: { lat: 52.2078853887462, lng: 18.258363333136916 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Poznańska 118, Kostrzyn',
    city: 'Kostrzyn',
    address: 'ul. Poznańska 118',
    openWorkdays: 'Pon-pt 7-21',
    openSaturday: '9-17',
    openSunday: '',
    position: { lat: 52.39798812084763, lng: 17.20832319205122 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'os. Leśne 24, Kozieglowy',
    city: 'Kozieglowy',
    address: 'os. Leśne 24',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: '6-16',
    openSunday: '7-14',
    position: { lat: 52.44863286634177, lng: 16.996691421207842 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Piaskowa  , Kozieglowy',
    city: 'Kozieglowy',
    address: 'ul. Piaskowa  ',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '6-14',
    openSunday: '',
    position: { lat: 52.441469451200014, lng: 17.00033727519159 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Poznańska 16A, Kozieglowy',
    city: 'Kozieglowy',
    address: 'ul. Poznańska 16A',
    openWorkdays: 'Pon-pt 5:30-18',
    openSaturday: '5:30-14',
    openSunday: '',
    position: { lat: 52.444005638607585, lng: 16.99219880530656 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Poznańska 87, Kórnik',
    city: 'Kórnik',
    address: 'ul. Poznańska 87',
    openWorkdays: 'pon-sb 7-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.25604178946663, lng: 17.077006847152965 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Krakowska 34, Poznań',
    city: 'Poznań',
    address: 'ul. Krakowska 34',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: '7-15',
    openSunday: '',
    position: { lat: 52.40094686348581, lng: 16.928631551655233 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Kraszewskiego 9, Poznań',
    city: 'Poznań',
    address: 'ul. Kraszewskiego 9',
    openWorkdays: 'pon-pt 6-18',
    openSaturday: '7-14',
    openSunday: '8-13',
    position: { lat: 52.411458256414676, lng: 16.9030282513455 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Książęca 15, Poznań',
    city: 'Poznań',
    address: 'ul. Książęca 15',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.36568701957861, lng: 16.932296723157876 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'oś. Pod Lipami paw 104, Poznań',
    city: 'Poznań',
    address: 'oś. Pod Lipami paw 104',
    openWorkdays: 'Pon-Pt 6-18',
    openSaturday: '6-14',
    openSunday: '',
    position: { lat: 52.43006625345292, lng: 16.94108155743712 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Literacka 47, Poznań',
    city: 'Poznań',
    address: 'ul. Literacka 47',
    openWorkdays: 'pon-pt 7-19',
    openSaturday: '8-14',
    openSunday: '',
    position: { lat: 52.44702449924831, lng: 16.873532586856186 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Dopiewska 8B, LUSÓWKO',
    city: 'LUSÓWKO',
    address: 'ul. Dopiewska 8B',
    openWorkdays: 'Pon-Pt 6-19',
    openSaturday: '7-14',
    openSunday: '8-14',
    position: { lat: 52.426186929390376, lng: 16.64517010811543 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Żabikowska 34, Luboń',
    city: 'Luboń',
    address: 'ul. Żabikowska 34',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.42618177877728, lng: 16.64516166135774 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Lwowska 2B, Poznań',
    city: 'Poznań',
    address: 'ul. Lwowska 2B',
    openWorkdays: 'pon-pt 6:30-18',
    openSaturday: '7-13',
    openSunday: '',
    position: { lat: 52.40116493488409, lng: 16.962607489794365 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'os. Łokietka 102, Poznań',
    city: 'Poznań',
    address: 'os. Łokietka 102',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.44929053883019, lng: 16.941508495157223 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Łukaszewicza 37, Poznań',
    city: 'Poznań',
    address: 'ul. Łukaszewicza 37',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.391116379534964, lng: 16.90267642493866 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Marcelińska 94d, Poznań',
    city: 'Poznań',
    address: 'ul. Marcelińska 94d',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '6-13',
    openSunday: '',
    position: { lat: 52.40390736419071, lng: 16.862087864222122 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Kolejowa 33, Mosina',
    city: 'Mosina',
    address: 'ul. Kolejowa 33',
    openWorkdays: 'pon-sb 7-21',
    openSaturday: '8-17',
    openSunday: '',
    position: { lat: 52.24296424849129, lng: 16.853352253983346 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Poznańska 10, Mrowino',
    city: 'Mrowino',
    address: 'ul. Poznańska 10',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.513723235530954, lng: 16.698097925696533 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Meissnera 4 lok U2, Poznań',
    city: 'Poznań',
    address: 'ul. Meissnera 4 lok U2',
    openWorkdays: 'Pon-Pt 6:30-18:30',
    openSaturday: '7-14',
    openSunday: '',
    position: { lat: 52.41975109561644, lng: 16.865155279095692 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Niemena 7C, Poznań',
    city: 'Poznań',
    address: 'ul. Niemena 7C',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '7-13',
    openSunday: '',
    position: { lat: 52.39611043872288, lng: 16.960386706068146 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Długa 2A, Murowana Goślina',
    city: 'Murowana Goślina',
    address: 'ul. Długa 2A',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '6-14',
    openSunday: '',
    position: { lat: 52.56703447161017, lng: 16.997974778347142 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Powstańców Wlkp 22, Oborniki',
    city: 'Oborniki',
    address: 'ul. Powstańców Wlkp 22',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: '7-15',
    openSunday: '8-14',
    position: { lat: 52.64436052157551, lng: 16.815049406469353 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Piłsudskiego 22, Oborniki',
    city: 'Oborniki',
    address: 'ul. Piłsudskiego 22',
    openWorkdays: 'Pon-pt 6.30-19',
    openSaturday: 'Sob 7-15',
    openSunday: '',
    position: { lat: 52.647659889029285, lng: 16.810742365072525 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Sienkiewicza 27, Opalenica',
    city: 'Opalenica',
    address: 'ul. Sienkiewicza 27',
    openWorkdays: 'Pon-Pt 7-20',
    openSaturday: 'Sob 6:30-17:30',
    openSunday: '',
    position: { lat: 52.30520052827617, lng: 16.41579007755993 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Oświęcimska 3, Poznań',
    city: 'Poznań',
    address: 'ul. Oświęcimska 3',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 6-14',
    openSunday: 'Nd 8-13',
    position: { lat: 52.35508060045606, lng: 16.942288436099222 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Opolska 14, Poznań',
    city: 'Poznań',
    address: 'ul. Opolska 14',
    openWorkdays: 'Pon-pt',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.3718829, lng: 16.9018443 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Opolska 90, Poznań',
    city: 'Poznań',
    address: 'ul. Opolska 90',
    openWorkdays: 'Pon 6-18',
    openSaturday: 'Sob 7-13',
    openSunday: '',
    position: { lat: 52.3618238, lng: 16.8893396 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Malinowa 35, Palędzie',
    city: 'Palędzie',
    address: 'ul. Malinowa 35',
    openWorkdays: 'Pon-pt 7-18',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.375917, lng: 16.7351339 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Zapłocie 20, Paczkowo',
    city: 'Paczkowo',
    address: 'ul. Zapłocie 20',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 6-14',
    openSunday: 'Niedz 8-14',
    position: { lat: 52.4013087, lng: 17.1595853 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Piaśnicka 7, Poznań',
    city: 'Poznań',
    address: 'ul. Piaśnicka 7',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-13',
    openSunday: '',
    position: { lat: 52.3878379, lng: 16.9810006 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Plac Górnika 2, Konin',
    city: 'Konin',
    address: 'ul. Plac Górnika 2',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.2274889, lng: 18.2534519 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Kostrzyńska 45, Pobiedziska',
    city: 'Pobiedziska',
    address: 'ul. Kostrzyńska 45',
    openWorkdays: 'Pon-pt 6:30-20',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.470272, lng: 17.2847703 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Odnowiciela 6, Pobiedziska',
    city: 'Pobiedziska',
    address: 'ul. Odnowiciela 6',
    openWorkdays: 'Pon-pt 6:30-18',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.477975, lng: 17.286428 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Polanka 4, Poznań',
    city: 'Poznań',
    address: 'ul. Polanka 4',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 7-13',
    openSunday: '',
    position: { lat: 52.3995543, lng: 16.9549342 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Rynkowa 121, Przeźmierowo',
    city: 'Przeźmierowo',
    address: 'ul. Rynkowa 121',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.4186544, lng: 16.7899679 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Dębowa 1a, Rabowice',
    city: 'Rabowice',
    address: 'ul. Dębowa 1a',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: 'Niedz 8-13',
    position: { lat: 52.3860658, lng: 17.1174532 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Racjonalizatorów 3, Poznań',
    city: 'Poznań',
    address: 'ul. Racjonalizatorów 3',
    openWorkdays: 'Pon-pt 7-15',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.3661209, lng: 16.9041762 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Szkolna 2a, Rokietnica',
    city: 'Rokietnica',
    address: 'ul. Szkolna 2a',
    openWorkdays: 'Pon-Pt 6-19',
    openSaturday: 'Sob 6:30-19',
    openSunday: 'Nd 8-18',
    position: { lat: 52.5109373, lng: 16.7412057 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Rolna 39, Poznań',
    city: 'Poznań',
    address: 'ul. Rolna 39',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 6-15',
    openSunday: '',
    position: { lat: 52.3801979, lng: 16.9155604 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Rycerska 35, Poznań',
    city: 'Poznań',
    address: 'ul. Rycerska 35',
    openWorkdays: 'Pon-pt 6-15',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.4028771, lng: 16.8716035 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'Rynek Jeżycki (stoisko handlowe) 247, Poznań',
    city: 'Poznań',
    address: 'Rynek Jeżycki (stoisko handlowe) 247',
    openWorkdays: 'Pon-pt 7-16',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.4120599, lng: 16.9037754 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'Rynek Łazarski 16, Poznań',
    city: 'Poznań',
    address: 'Rynek Łazarski 16',
    openWorkdays: 'Pon-pt 6-17',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.3940314, lng: 16.9012491 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'Os. Rzeczypospolitej 4A, Poznań',
    city: 'Poznań',
    address: 'Os. Rzeczypospolitej 4A',
    openWorkdays: 'Pon-pt 6-20',
    openSaturday: 'Sob 7-13',
    openSunday: '',
    position: { lat: 52.3886513, lng: 16.9499713 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Kościelna 1, Siekierki',
    city: 'Siekierki',
    address: 'ul. Kościelna 1',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: 'Niedz 8-13',
    position: { lat: 52.38127, lng: 17.1635562 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'Aleja Handlowa 153, Skorzęcin',
    city: 'Skorzęcin',
    address: 'Aleja Handlowa 153',
    openWorkdays: 'Pon-Ndz 7-20',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.4596536, lng: 17.8921016 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Poznańska 98, Skórzewo',
    city: 'Skórzewo',
    address: 'ul. Poznańska 98',
    openWorkdays: 'Pon-Sob 6-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.3954238, lng: 16.8002295 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'Os. Przyjaźni PAW 125, Poznań',
    city: 'Poznań',
    address: 'Os. Przyjaźni PAW 125',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.4320756, lng: 16.9298624 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Słowiańska 101, Poznań',
    city: 'Poznań',
    address: 'ul. Słowiańska 101',
    openWorkdays: 'Pon-Pt 6-19',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.4288759, lng: 16.9437295 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Niepodległości 5, Słupca',
    city: 'Słupca',
    address: 'ul. Niepodległości 5',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.2945936, lng: 17.8744454 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Warszawska 23/4, Słupca',
    city: 'Słupca',
    address: 'ul. Warszawska 23/4',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.2869355, lng: 17.8749925 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Smolna 13, Poznań',
    city: 'Poznań',
    address: 'ul. Smolna 13',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.4229843, lng: 16.9658895 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'Os. Przyjaźni paw 50, Poznań',
    city: 'Poznań',
    address: 'Os. Przyjaźni paw 50',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.4293848, lng: 16.9225145 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Olchowa 12/3, Stare Miasto',
    city: 'Stare Miasto',
    address: 'ul. Olchowa 12/3',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.3740791, lng: 16.8981064 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'Os. Stare Żegrze 109, Poznań',
    city: 'Poznań',
    address: 'Os. Stare Żegrze 109',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: 'Nd HANDLOWA 8-17',
    openSunday: '',
    position: { lat: 52.3834705, lng: 16.9668851 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Błonie 7, Stęszew',
    city: 'Stęszew',
    address: 'ul. Błonie 7',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: 'Nd 8-17',
    openSunday: '',
    position: { lat: 52.27797, lng: 16.6898158 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Szyperska 10a, Poznań',
    city: 'Poznań',
    address: 'ul. Szyperska 10a',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 7-13',
    openSunday: '',
    position: { lat: 52.4119252, lng: 16.9406276 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Skibowa 16A, Poznań',
    city: 'Poznań',
    address: 'ul. Skibowa 16A',
    openWorkdays: 'Pon-Sob 6-21',
    openSaturday: '',
    openSunday: '',
    position: { lat: 52.3634713, lng: 17.0096198 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Jana Pawła II 10, Szamotuły',
    city: 'Szamotuły',
    address: 'ul. Jana Pawła II 10',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: 'Nd HANDLOWA 8-17',
    openSunday: '',
    position: { lat: 52.6105473, lng: 16.5862132 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Zamkowa 9, Szamotuły',
    city: 'Szamotuły',
    address: 'ul. Zamkowa 9',
    openWorkdays: 'Pon 6-19',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.6180309, lng: 16.5801452 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Szarych Szeregów 12/13, Poznań',
    city: 'Poznań',
    address: 'ul. Szarych Szeregów 12/13',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.4587965, lng: 16.8801504 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Działyńskiego , Swarzędz',
    city: 'Swarzędz',
    address: 'ul. Działyńskiego ',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.3994766, lng: 17.0649571 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Szamarzewskiego 39 U /1, Poznań',
    city: 'Poznań',
    address: 'ul. Szamarzewskiego 39 U /1',
    openWorkdays: 'Pon-Pt 6-18',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.4115284, lng: 16.8956447 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Jeziorna 39/3, Szczytniki',
    city: 'Szczytniki',
    address: 'ul. Jeziorna 39/3',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.2994003, lng: 17.0115719 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. 20 Października 22, Środa Wlkp',
    city: 'Środa Wlkp',
    address: 'ul. 20 Października 22',
    openWorkdays: 'Pon-Sob 7-21',
    openSaturday: 'Nd HANDLOWA 8-17',
    openSunday: '',
    position: { lat: 52.2257544, lng: 17.2743023 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Wrzosowa 4 4, Środa Wlkp',
    city: 'Środa Wlkp',
    address: 'ul. Wrzosowa 4 4',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 7-15',
    openSunday: '',
    position: { lat: 52.2380239, lng: 17.2666271 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Św. Marcin 37, Poznań',
    city: 'Poznań',
    address: 'ul. Św. Marcin 37',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.4061197, lng: 16.9243553 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Warszawska 96, Poznań',
    city: 'Poznań',
    address: 'ul. Warszawska 96',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: 'Nd 8-13',
    position: { lat: 52.4093046, lng: 16.9838762 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'Os. Wichrowe Wzgórze 104, Poznań',
    city: 'Poznań',
    address: 'Os. Wichrowe Wzgórze 104',
    openWorkdays: 'Pon-Pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.4389763, lng: 16.9398878 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Nowa 1, Witkowo',
    city: 'Witkowo',
    address: 'ul. Nowa 1',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.4410592, lng: 17.7731586 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Mickiewicza 13, Wronki',
    city: 'Wronki',
    address: 'ul. Mickiewicza 13',
    openWorkdays: 'Pon-Sob 6-21',
    openSaturday: 'Nd HANDLOWA 7:30-17',
    openSunday: '',
    position: { lat: 52.7097085, lng: 16.3777703 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Mickiewicza 39a, Wronki',
    city: 'Wronki',
    address: 'ul. Mickiewicza 39a',
    openWorkdays: 'Pon-Pt 6-18',
    openSaturday: 'Sob 6-12',
    openSunday: '',
    position: { lat: 52.7100074, lng: 16.3831252 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Chrobrego 11, Września',
    city: 'Września',
    address: 'ul. Chrobrego 11',
    openWorkdays: 'Pon-pt 6-20',
    openSaturday: 'Sob 6-15',
    openSunday: '',
    position: { lat: 52.3239568, lng: 17.5824243 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Gnieźnińska 1, Września',
    city: 'Września',
    address: 'ul. Gnieźnińska 1',
    openWorkdays: 'Pon-pt 6-20',
    openSaturday: 'Sob 6-15',
    openSunday: '',
    position: { lat: 52.3291961, lng: 17.5638988 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Bronisławy Śmidowicz 8, Września',
    city: 'Września',
    address: 'ul. Bronisławy Śmidowicz 8',
    openWorkdays: 'Pon-pt 6-18',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.3280893, lng: 17.5920333 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Warszawska 20, Września',
    city: 'Września',
    address: 'ul. Warszawska 20',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 6-14',
    openSunday: '',
    position: { lat: 52.3252809, lng: 17.571727 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Żródlana 23, Poznań',
    city: 'Poznań',
    address: 'ul. Żródlana 23',
    openWorkdays: 'Pon-Pt 7-19',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.426624, lng: 16.9113559 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Nadolnik 9, Poznań',
    city: 'Poznań',
    address: 'ul. Nadolnik 9',
    openWorkdays: 'Pon-Pt 6-19',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.4234365, lng: 16.9724911 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
  {
    title: 'ul. Kórnicka 135, Zalasewo',
    city: 'Zalasewo',
    address: 'ul. Kórnicka 135',
    openWorkdays: 'Pon-pt 6-19',
    openSaturday: 'Sob 7-14',
    openSunday: '',
    position: { lat: 52.3946037, lng: 17.0765146 },
    pizza: false,
    iceCream: false,
    coffee: false,
  },
];

import { Place, Shop } from '../modules/map/place';
import { SingleTypeResponse, fromSingleResponse } from './response';

export interface ShopsDto {
  Shop: {
    address: string;
    id: number;
    openSaturday: string;
    openSunday: string;
    openWorkdays: string;
    title: string;
    position: {
      lat: number;
      lng: number;
    };
  }[];
}

export function toShops(response: SingleTypeResponse<ShopsDto>): Place<Shop>[] {
  const shops: ShopsDto = fromSingleResponse(response);
  return shops.Shop.map((shop) => ({
    title: shop.title,
    position: {
      lat: +shop.position.lat,
      lng: +shop.position.lng,
    },
    details: {
      address: shop.address,
      open: {
        workdays: shop.openWorkdays,
        saturday: shop.openSaturday,
        sunday: shop.openSunday,
      },
    },
  }));
}

import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken<Environment>('updogEnvironment');

// TODO move to map lib
export const MAP_KEY = new InjectionToken<string>('updogMapKey');

export interface Environment {
  API_URL: string;
  API_AUTH: string;
  GOOGLE_MAPS_KEY: string;
  GOOGLE_MAPS_ID: string;
}

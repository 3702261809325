<header>
  <h2>Gdzie nas znaleźć?</h2>
  <input [formControl]="input" type="text" placeholder="Ulica, miasto, kod pocztowy">
</header>

<section>
  <div class="list-wrapper">
    <ng-content select=".map-header"></ng-content>

    @if (filteredPlaces$ | async; as filteredPlaces) {
    <ul>
      @for (place of filteredPlaces; track place) {
      <li class="place-info">
        <strong>{{place.title}}</strong>
        <p class="address">{{place.details.address}}</p>
        <div>
          <p>Pon-Wt: {{place.details.open.workdays}}</p>
          <p>Sobota: {{place.details.open.saturday}}</p>
          <p>Niedziela: {{place.details.open.sunday}}</p>
        </div>
      </li>
      }
    </ul>
    }
  </div>
  <div class="map-wrapper">
    @if (loaded$ | async) {
    <google-map width="100%" height="100%">
      @if (filteredPlaces$ | async; as filteredPlaces) {
      @for (place of filteredPlaces; track place) {
      <map-marker #marker="mapMarker" [position]="place.position"
        (mapClick)="openInfoWindow(marker, place)"></map-marker>
      }
      }
      <map-info-window>
        @if (openedPlace) {
        <div class="place-info">
          <strong>{{openedPlace.title}}</strong>
          <p class="address">{{openedPlace.details.address}}</p>
          <div>
            <p>Pon-Wt: {{openedPlace.details.open.workdays}}</p>
            <p>Sobota: {{openedPlace.details.open.saturday}}</p>
            <p>Niedziela: {{openedPlace.details.open.sunday}}</p>
          </div>
        </div>
        }
      </map-info-window>
    </google-map>
    }
  </div>
</section>
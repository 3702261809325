<nav class="navigation">
  <ul class="navigation__container">
    @for (link of navLinks$ | async; track link; let index = $index) {
    @if(index < 2) { @if(link.route[0]==='#' ) { <li>
      <a (click)="scrollTo(link.route)" class="navigation__link">{{link.title}}</a>
      </li>
      } @else {
      <li>
        <a (click)="deactivateMenu()" routerLink="{{link.route}}" class="navigation__link">{{link.title}}</a>
      </li>
      }
      }
      }

      @if(config$ | async; as config) {
      <li class="logo-container">
        <a routerLink="/">
          <img src="{{config.mainLogo}}" alt="" class="logo" />
        </a>
      </li>
      }

      @for (link of navLinks$ | async; track link; let index = $index) {
      @if(index >= 2) {
      <li>
        <a routerLink="{{link.route}}" class="navigation__link">{{link.title}}</a>
      </li>
      }
      }
  </ul>
</nav>

<nav class="navigation-mobile" [ngClass]="[menuIsActive ? 'active' : '']">

  @if(config$ | async; as config) {
  <a routerLink="/">
    <img src="{{config.mainLogo}}" alt="" class="logo" />
  </a>
  }

  <div class="menu-icon" (click)="openMenu()">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>

  <ul class="nav-list">
    <li>
      <a (click)="deactivateMenu()" routerLink="/" class="nav-list__link">Strona główna</a>
    </li>
    @for (link of navLinks$ | async; track link; let index = $index) {

    @if(link.route[0] === '#') {
    <li>
      <a (click)="scrollTo(link.route)" class="nav-list__link">{{link.title}}</a>
    </li>
    } @else {
    <li>
      <a (click)="deactivateMenu()" routerLink="{{link.route}}" class="nav-list__link">{{link.title}}</a>
    </li>
    }
    }

    @if(config$ | async; as config) {
    <a href="{{config.facebook}}" target="_blank">
      <img src="{{config.facebookLogo}}" alt="facebook icon" class="social" />
    </a>
    }
  </ul>
</nav>
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { ApiInterceptor } from './core/api.interceptor';
import { AuthInterceptor } from './core/auth.interceptor';
import { ENVIRONMENT, Environment, MAP_KEY } from './core/tokens';
import { FooterComponent } from './modules/footer/footer/footer.component';
import { NavigationComponent } from './modules/navigation/navigation/navigation.component';
import { MapModule } from './modules/map/map.module';

declare module 'aos';
import AOS from 'aos';

export function initializeAOS() {
  return () => AOS.init({
    duration: 600,
    once: true,
    easing: 'ease-in-out',
    throttleDelay: 200,
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      bindToComponentInputs: true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
    NavigationComponent,
    FooterComponent,
    MapModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAOS,
      multi: true,
    },
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: MAP_KEY,
      useFactory: (env: Environment) => env.GOOGLE_MAPS_KEY,
      deps: [ENVIRONMENT],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

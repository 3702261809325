import { Component, inject } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { Observable } from 'rxjs';
import { Page } from '../../../models/page';
import { Router, RouterLink } from '@angular/router';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Config } from '../../../models/config';

@Component({
  selector: 'updog-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AsyncPipe
  ],
})
export class NavigationComponent {
  public navLinks$: Observable<Page[]> = this.api.getNavLinks();
  public config$: Observable<Config> = this.api.getConfig();
  public menuIsActive = false;

  router = inject(Router);

  constructor(private readonly api: ApiService) { }

  scrollTo(id: string): void {
    this.router.navigate([], { fragment: id.replace('#', '') });
    this.menuIsActive = false;
  }

  public openMenu() {
    this.menuIsActive = !this.menuIsActive;
  }

  public deactivateMenu() {
    this.menuIsActive = false;
  }
}

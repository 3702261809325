import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ApiService } from '../../../api/api.service';
import { Asset } from '../../../models/response';
import { MapComponent } from '../../map/map/map.component';

export interface Footer {
  banner: {
    url: string;
    image: Asset;
  };
  image: Asset;
  facebook: string;
  linkedin: string;
  footerBottomText: string;
  footerBottomSubText: string;
  section: {
    title: string;
    content: string;
    url: string;
  }[];
}

@Component({
  selector: 'updog-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterLink, MapComponent, AsyncPipe],
})
export class FooterComponent {
  private api = inject(ApiService);
  vm$ = this.api.getFooter();
  readonly map$ = this.api.getMap();
}

import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapInitService } from './map-init.service';
import { MapComponent } from './map/map.component';

@NgModule({
    imports: [
        CommonModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        ReactiveFormsModule,
        MapComponent,
    ],
    providers: [MapInitService],
    exports: [MapComponent],
})
export class MapModule {}

import { environment } from '../../environments/environment';
import { Asset, SingleTypeResponse, fromSingleResponse } from './response';

export interface ConfigDto {
  title: string;
  subtitle: string;
  address: string;
  mainLogo: Asset;
  secondaryLogo: Asset;
  facebook: string;
  facebookLogo: Asset;
}

export interface Config {
  title: string;
  subtitle: string;
  address: string;
  mainLogo: string;
  secondaryLogo: string;
  facebook: string;
  facebookLogo: string;
}

export function toConfig(response: SingleTypeResponse<ConfigDto>): Config {
  const data = fromSingleResponse(response);

  return {
    ...data,
    mainLogo: `${data.mainLogo.url}`,
    secondaryLogo: data.secondaryLogo ? `${data.secondaryLogo.url}` : '',
    facebookLogo: data.facebookLogo ? `${data.facebookLogo.url}` : '',
  };
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.method === 'JSONP') {
      return next.handle(request);
    }

    const token = environment.API_AUTH;
    const authReq = request.clone({
      headers: request.headers.set('Authorization', `bearer ${token}`),
    });

    return next.handle(authReq);
  }
}
